import { ref, watch } from '@vue/composition-api'

export default (props, { emit }) => {
  const number = ref(parseInt(props.value, 10))

  watch(
    () => props.value, // Watch the value prop
    newValue => {
      number.value = parseInt(newValue, 10) // Update number whenever the parent value changes
    },
  )

  const add = () => {
    if (number.value < 0) {
      number.value = 0
    } else {
      number.value += 1
    }
    emit('input', number.value)
  }

  const sub = () => {
    number.value -= 1
    if (number.value < 0) {
      number.value = 0
    }

    emit('input', number.value)
  }

  const updateNumber = value => {
    let newValue = value
    if (!Number.isInteger(value) || value < 0) {
      newValue = 0
    }

    number.value = parseInt(newValue, 10)
    emit('input', parseInt(newValue, 10))
  }

  return {
    add,
    sub,
    updateNumber,

    number,
  }
}
