import { capitalizeFirstLetter, cloneNested, transformDateToHumanReadablePretty } from '@/helpers/helpers'
import { computed, ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import useAppConfig from '@core/app-config/useAppConfig'
import store from '@/store/store'
import { $themeBreakpoints } from '@themeConfig'
import _ from 'lodash'

export default () => {
  const { skin } = useAppConfig()
  const isDark = computed(() => skin.value === 'dark')
  const refreshKey = ref(0)
  const weeks = ref({})

  const userRolesForScheduling = computed(() => store.getters['appConfig/config'].schedule.userRolesForScheduling)
  const shiftDefinitions = computed(() => store.getters['appConfig/config'].schedule.shiftDefinitions)

  const currentMonth = ref(dayjs().startOf('month').add(1, 'month'))
  const periodStart = computed(() => currentMonth.value.startOf('week'))
  const periodEnd = computed(() => currentMonth.value.add(1, 'month').endOf('week'))

  const teamNames = computed(() => store.getters['settings/getTeamNames'])
  const teamNamesAsCheckboxOptions = computed(() => teamNames.value.map(teamName => ({ text: teamName.name, value: teamName.id })))

  const isMobileWidth = computed(() => $themeBreakpoints.lg > store.state.app.windowWidth)

  const dayjsWeekdays = computed(() => {
    const weekdays = cloneNested(dayjs.localeData().weekdays())
    if (dayjs.localeData().firstDayOfWeek() === 1) {
      weekdays.push(weekdays.shift())
    }
    return weekdays
  })
  const loading = ref(false)

  const defaultQuantityModel = {}
  shiftDefinitions.value.forEach(shiftDefinition => {
    defaultQuantityModel[shiftDefinition.shiftDefinitionIdentifier] = { roles: [], teams: [] }
    userRolesForScheduling.value.forEach(role => {
      defaultQuantityModel[shiftDefinition.shiftDefinitionIdentifier].roles.push({
        role,
        quantity: 0,
      })
    })
  })

  const updateHeadcountsByStaffingNeeds = (staffingNeeds, shiftDefinitionIdentifierToUpdate = null, modifier = null) => {
    const updatedDates = []
    staffingNeeds.forEach(staffingNeed => {
      const { shiftDefinitionIdentifier } = staffingNeed
      if (shiftDefinitionIdentifierToUpdate && shiftDefinitionIdentifierToUpdate !== shiftDefinitionIdentifier) {
        return
      }

      weeks.value.forEach((week, weekIndex) => {
        week.days.forEach((day, dayIndex) => {
          if (!day.isCurrentMonth) {
            return
          }
          let date = dayjs(staffingNeed.date)
          if (modifier) {
            date = date.add(modifier, 'day')
          }

          if (date.isSame(day.date, 'day')) {
            day.headcounts[shiftDefinitionIdentifier].roles.forEach((role, roleIndex) => {
              if (role.role === staffingNeed.role) {
                weeks.value[weekIndex].days[dayIndex].headcounts[shiftDefinitionIdentifier].roles[roleIndex].quantity = staffingNeed.quantity
              }
            })

            staffingNeed.teamNames.forEach(teamName => {
              weeks.value[weekIndex].days[dayIndex].headcounts[shiftDefinitionIdentifier].teams.push(teamName.id)
            })

            if (!updatedDates.includes(date.format('YYYY-MM-DD'))) {
              updatedDates.push(date.format('YYYY-MM-DD'))
            }
          }
        })
      })
    })

    return updatedDates
  }

  const fetchStaffingNeeds = () => {
    loading.value = true
    store.dispatch(
      'schedules/fetchStaffingNeeds',
      {
        dateFrom: currentMonth.value.startOf('month').format('YYYY-MM-DD'),
        dateTo: currentMonth.value.endOf('month').format('YYYY-MM-DD'),
      },
    )
      .then(response => {
        updateHeadcountsByStaffingNeeds(response.data)
      })
      .catch(err => {
        window.toast.notify.danger(err.message)
        throw err
      })
      .finally(() => {
        loading.value = false
      })
  }

  const init = () => {
    loading.value = true
    let date = periodStart.value.clone()
    let week = {}
    const month = []

    while (date < periodEnd.value) {
      week = {
        number: date.week(),
        days: [],
      }
      for (let i = 0; i < 7; i += 1) {
        week.days.push({
          date,
          weekday: date.weekday(),
          formatted: date.format('YYYY-MM-DD'),
          isToday: date.isToday(),
          isCurrentMonth: currentMonth.value.format('YYYYMM') === date.format('YYYYMM'),
          isPast: date.isBefore(dayjs(), 'day'),
          headcounts: _.cloneDeep(defaultQuantityModel),
        })

        date = date.add(1, 'day')
      }
      month.push(week)
      week = []
    }

    weeks.value = month
    fetchStaffingNeeds()
  }

  store.dispatch('settings/fetchTeamNames', { sortField: 'name', sortOrder: 'asc' })
    .then(() => {
      init()
    })

  const nextMonth = () => {
    currentMonth.value = currentMonth.value.add(1, 'month')
    init()
  }

  const prevMonth = () => {
    currentMonth.value = currentMonth.value.subtract(1, 'month')
    init()
  }

  const daysTimeouts = {}
  const update = (day, headcounts, timeout = 1000) => {
    const date = day.format('YYYY-MM-DD')
    if (daysTimeouts[date]) {
      clearTimeout(daysTimeouts[date])
    }

    daysTimeouts[date] = setTimeout(() => {
      store.dispatch('schedules/updateStaffingNeeds', { date, headcounts })
        .catch(err => {
          window.toast.notify.danger(err.message)
          init()
          throw err
        })
    }, timeout)
  }

  const resetHeadcounts = (shiftDefinitionIdentifier, modifier, dayFromParam, dayToParam) => {
    const dayFrom = dayFromParam.add(modifier, 'day')
    const dayTo = dayToParam.add(modifier, 'day')

    weeks.value.forEach((week, weekKey) => {
      week.days.forEach((day, dayKey) => {
        if (!day.isCurrentMonth) {
          return
        }
        if (day.date.isBefore(dayFrom) || day.date.isAfter(dayTo)) {
          return
        }

        day.headcounts[shiftDefinitionIdentifier].roles.forEach((role, roleKey) => {
          weeks.value[weekKey].days[dayKey].headcounts[shiftDefinitionIdentifier].roles[roleKey].quantity = 0
        })
        weeks.value[weekKey].days[dayKey].headcounts[shiftDefinitionIdentifier].teams = []
      })
    })
  }

  const copyFromDay = (shiftDefinitionIdentifier, modifier, dayFrom, dayToParam = null) => {
    let dayTo = dayToParam
    if (!dayTo) {
      dayTo = dayFrom
    }

    resetHeadcounts(shiftDefinitionIdentifier, modifier, dayFrom, dayTo)

    store.dispatch(
      'schedules/fetchStaffingNeeds',
      {
        dateFrom: dayFrom.format('YYYY-MM-DD'),
        dateTo: dayTo.format('YYYY-MM-DD'),
      },
    )
      .then(response => {
        const updatedDates = updateHeadcountsByStaffingNeeds(response.data, shiftDefinitionIdentifier, modifier)
        updatedDates.forEach(date => {
          weeks.value.forEach(week => {
            week.days.forEach(day => {
              if (day.date.format('YYYY-MM-DD') !== date) {
                return
              }
              update(
                dayjs(date),
                day.headcounts,
                0,
              )
            })
          })
        })
      })
  }

  return {
    isDark,
    shiftDefinitions,

    refreshKey,
    currentMonth,
    dayjsWeekdays,
    weeks,
    isMobileWidth,
    loading,

    update,

    nextMonth,
    prevMonth,
    copyFromDay,

    teamNamesAsCheckboxOptions,

    dayjs,
    capitalizeFirstLetter,
    transformDateToHumanReadablePretty,
  }
}
