<template>
  <div>
    <label v-if="label">{{ label }}</label>
    <b-input-group class="input-group-merge">
      <b-input-group-prepend>
        <b-button
          variant="outline-secondary"
          class="px-50"
          @click="sub"
        >
          <feather-icon icon="MinusIcon" />
        </b-button>
      </b-input-group-prepend>
      <b-input
        v-model="number"
        class="text-center"
        type="number"
        @input="updateNumber"
        @focus="$event.target.select()"
      />
      <b-input-group-append>
        <b-button
          variant="outline-secondary"
          class="px-50"
          @click="add"
        >
          <feather-icon icon="PlusIcon" />
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
import setup from './setup'

export default {
  props: {
    value: {
      type: Number,
      required: true,
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
  },
  setup,
}
</script>

<style scoped lang="scss">

</style>
