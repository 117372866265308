<template>
  <div>
    <b-row class="mb-1">
      <b-col>
        <h2 class="mt-25">
          {{ capitalizeFirstLetter(currentMonth.format('MMMM YYYY')) }}
        </h2>
      </b-col>
      <b-col class="text-right">
        <b-btn-group>
          <b-button
            variant="secondary"
            @click="prevMonth"
          >
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
          <b-button
            variant="secondary"
            :disabled="currentMonth.subtract(6, 'months').isAfter(dayjs())"
            @click="nextMonth"
          >
            <feather-icon icon="ChevronRightIcon" />
          </b-button>
        </b-btn-group>
      </b-col>
    </b-row>

    <b-tabs
      pills
    >
      <b-tab
        v-for="(shiftDefinition,shiftDefinitionKey) in shiftDefinitions"
        :key="`shift-definition-${shiftDefinition.shiftDefinitionIdentifier}`"
        :active="shiftDefinitionKey === 0"
        :title="`${$t('schedule.shift')} ${shiftDefinition.name}`"
      >
        <rc-overlay
          :show="loading"
          rounded
        >
          <b-card>
            <div class="overflow-auto">
              <table class="schedule-calendar-table w-100">
                <thead>
                  <tr>
                    <th />
                    <th
                      v-for="(day, weekdayKey) in dayjsWeekdays"
                      :key="`weekday-key-${weekdayKey}`"
                      class="d-none d-lg-table-cell text-center"
                    >
                      <div class="m-50">
                        <span class="font-weight-bold text-uppercase font-medium-3 mb-50 d-block">{{ day }}</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(week, weekKey) in weeks"
                    :key="`week-key-${weekKey}`"
                    class="d-block d-lg-table-row"
                  >
                    <td
                      class="text-uppercase d-block d-lg-table-cell align-text-top"
                    >
                      <div class="m-50">
                        <b-button
                          v-b-tooltip.auto
                          :variant="isDark ? 'dark' : 'light'"
                          class="btn-block on-hover"
                          :title="$t('schedule.staffing-needs.copy-week')"
                          @click="copyFromDay(shiftDefinition.shiftDefinitionIdentifier, 7, week.days[0].date.subtract(1, 'week'), week.days[6].date.subtract(1, 'week') )"
                        >
                          <span class="show-on-hover">
                            <feather-icon icon="CopyIcon" />
                          </span>
                          <span class="hide-on-hover">
                            <span class="d-lg-none">
                              {{ $t('schedule.my-availability.week') }}
                            </span>
                            {{ week.number }}
                          </span>
                        </b-button>
                      </div>
                    </td>
                    <td
                      v-for="(day, dayKey) in week.days"
                      :key="`day-key-${dayKey}-${refreshKey}`"
                      :style="{ 'width': !isMobileWidth ? '13%' : 'inherit' }"
                      class="d-block d-lg-table-cell align-text-top"
                    >
                      <div :class="{'d-none': !day.isCurrentMonth && isMobileWidth}">
                        <div
                          :id="`day-label-${day.formatted}-${shiftDefinition.shiftDefinitionIdentifier}`"
                          :class="{'text-muted': !day.isCurrentMonth, 'font-weight-bold text-uppercase font-medium-3 d-inline-block': true}"
                        >
                          <span class="d-lg-none">
                            {{ capitalizeFirstLetter(day.date.format('dddd')) }},
                            {{ transformDateToHumanReadablePretty(day.date.format()) }}
                          </span>
                          <b-avatar
                            :class="{'d-none d-lg-block font-medium-3 cursor-pointer': true, 'text-muted': !day.isCurrentMonth }"
                            :variant="day.isCurrentMonth ? 'light-secondary' : 'none'"
                            size="36"
                          >
                            {{ day.date.format('D') }}
                          </b-avatar>
                        </div>

                        <b-popover
                          v-if="day.isCurrentMonth"
                          :target="`day-label-${day.formatted}-${shiftDefinition.shiftDefinitionIdentifier}`"
                          triggers="hover"
                          placement="bottom"
                        >
                          <b-button-group size="sm">
                            <b-button
                              variant="light"
                              class="text-nowrap"
                              @click="copyFromDay(shiftDefinition.shiftDefinitionIdentifier, 1, day.date.subtract(1, 'day') )"
                            >
                              <feather-icon icon="CopyIcon" />
                              <feather-icon icon="ArrowRightIcon" />
                            </b-button>
                            <b-button
                              variant="light"
                              class="text-nowrap"
                              @click="copyFromDay(shiftDefinition.shiftDefinitionIdentifier, 7, day.date.subtract(7, 'day') )"
                            >
                              <feather-icon icon="CopyIcon" />
                              <feather-icon icon="ArrowDownIcon" />
                            </b-button>
                          </b-button-group>
                        </b-popover>
                      </div>

                      <div
                        v-show="day.isCurrentMonth"
                        class="m-50"
                      >
                        <div
                          v-for="role in day.headcounts[shiftDefinition.shiftDefinitionIdentifier].roles"
                          :key="`role-for-scheduling-${day.formatted}-${role.role}`"
                          class="mb-1"
                        >
                          {{ $t(`roles.${role.role}`) }}
                          <number-input
                            v-model="role.quantity"
                            @input="update(day.date, day.headcounts)"
                          />

                        </div>

                        <b-form-group
                          v-slot="{ ariaDescribedby }"
                          :label="$t('schedule.staffing-needs.teams')"
                        >
                          <b-checkbox-group
                            v-model="day.headcounts[shiftDefinition.shiftDefinitionIdentifier].teams"
                            :options="teamNamesAsCheckboxOptions"
                            :aria-describedby="ariaDescribedby"
                            :name="`teams-checkbox-${day.formatted}`"
                            @change="update(day.date, day.headcounts)"
                          />
                        </b-form-group>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-card>
        </rc-overlay>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import setup from '@/views/schedule/planner/StaffingNeeds/setup'
import NumberInput from '@/views/_global/number-input/NumberInput.vue'
import { BCol } from 'bootstrap-vue'
import RcOverlay from '@/layouts/components/rc/RcOverlay.vue'

export default {
  components: { RcOverlay, BCol, NumberInput },
  setup,
}

</script>
<style scoped lang="scss">
.on-hover{
  .show-on-hover{
    display: none;
  }
  &:hover{
    .show-on-hover{
      display: block;
    }
    .hide-on-hover{
      display: none;
    }
  }
}
</style>
