<template>
  <div>
    <b-tabs
      align="left"
      pills
    >
      <b-tab
        lazy
        :active="true"
      >
        <template #title>
          <feather-icon icon="CalendarIcon" />
          {{ $t('schedule.planner.action-title') }}
        </template>
        <shifts-planner />
      </b-tab>

      <b-tab lazy>
        <template #title>
          <feather-icon icon="UsersIcon" />
          {{ $t('schedule.staffing-needs.title') }}
        </template>

        <staffing-needs />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import StaffingNeeds from '@/views/schedule/planner/StaffingNeeds/StaffingNeeds.vue'
import ShiftsPlanner from '@/views/schedule/planner/ShiftsPlanner/ShiftsPlanner.vue'

export default {
  components: {
    ShiftsPlanner,
    StaffingNeeds,
  },
}
</script>

<style lang="scss">
</style>
