<template>
  <div>

    <b-row class="mb-1">
      <b-col>
        <h2 class="mt-25">
          {{ capitalizeFirstLetter(currentMonth.format('MMMM YYYY')) }}
        </h2>
      </b-col>
      <b-col class="text-right">
        <b-button
          class="mr-1"
          variant="flat-secondary"
          @click="generateSchedule()"
        >
          <feather-icon icon="MagicWandIcon" />
        </b-button>
        <b-btn-group>
          <b-button
            variant="secondary"
            @click="prevMonth"
          >
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
          <b-button
            variant="secondary"
            :disabled="currentMonth.subtract(6, 'months').isAfter(dayjs())"
            @click="nextMonth"
          >
            <feather-icon icon="ChevronRightIcon" />
          </b-button>
        </b-btn-group>
      </b-col>
    </b-row>

    <div
      class="accordion"
      role="tablist"
    >
      <b-card
        v-for="day in days"
        :key="`day-button-${day.formatted}`"
        no-body
        class="mb-1"
      >
        <b-card-header
          header-tag="header"
          class="p-1 d-block"
          role="tab"
        >
          <b-row>
            <b-col>
              <b-button
                v-b-toggle="`accordion-${day.formatted}`"
                block
                variant="flat-primary"
                class="text-left pl-50 font-medium-1"
              >
                {{ capitalizeFirstLetter(day.date.format('dddd')) }},
                <span class="text-nowrap">{{ day.date.format('D MMMM YYYY') }}</span>
                <b-avatar
                  v-if="day.isFulfilled !== null"
                  :variant="day.isFulfilled ? 'success' : 'light-secondary'"
                  pill
                  size="sm"
                  class="ml-50 mt-n25 position-absolute"
                >
                  <feather-icon :icon="day.isFulfilled ? 'CheckIcon' : 'XIcon'" />
                </b-avatar>
              </b-button>
            </b-col>
            <b-col
              cols="2"
              md="1"
            >
              <b-button
                class="font-medium-1 px-0 "
                variant="flat-secondary"
                block
                @click="generateSchedule(day.date)"
              >
                <feather-icon icon="MagicWandIcon" />
              </b-button>
            </b-col>
          </b-row>
        </b-card-header>

        <b-collapse
          :id="`accordion-${day.formatted}`"
          accordion="days-accordion"
          visible
          @show="fetchOptions(day.formatted)"
          @shown="scrollToCollapse(`accordion-${day.formatted}`)"
        >
          <b-tabs
            pills
            class="mx-2 mb-2"
            @activate-tab="fetchOptions(day.formatted)"
          >
            <b-tab
              v-for="(shiftDefinition,shiftDefinitionKey) in shiftDefinitions"
              :key="`shift-definition-${shiftDefinition.shiftDefinitionIdentifier}`"
            >
              <template #title>
                <div class="d-inline-block mr-50">
                  {{ $t('schedule.shift') }} {{ shiftDefinition.name }}
                </div>
                <b-badge
                  v-if="day.schedules[shiftDefinition.shiftDefinitionIdentifier].isFulfilled !== null"
                  :variant="day.schedules[shiftDefinition.shiftDefinitionIdentifier].isFulfilled ? 'success' : 'secondary'"
                  pill
                  size="sm"
                >
                  <feather-icon
                    :icon="day.schedules[shiftDefinition.shiftDefinitionIdentifier].isFulfilled ? 'CheckIcon' : 'XIcon'"
                    class="mr-0"
                  />
                </b-badge>
              </template>
              <rc-overlay :show="day.loadingSchedules">
                <b-row align-v="stretch">
                  <b-col
                    v-for="(roleShifts, role) in day.schedules[shiftDefinition.shiftDefinitionIdentifier].roles"
                    :key="`role-shifts-${day.formatted}-${shiftDefinitionKey}-${role}`"
                    md="6"
                    class="mb-2"
                  >
                    <div class="border rounded p-1 h-100">
                      <h4>
                        {{ $t('roles.plural.' + role) }}
                        <b-badge :variant="roleShifts.headcounts !== roleShifts.plannedShifts.length ? 'danger' : 'success'">
                          {{ roleShifts.plannedShifts.length }} / {{ roleShifts.headcounts }}
                        </b-badge>
                      </h4>
                      <b-button
                        v-for="(plannedShift, plannedShiftIndex) in roleShifts.plannedShifts"
                        :key="`plannedShift-${shiftDefinition.shiftDefinitionIdentifier}-${day.formatted}-${shiftDefinitionKey}-${role}-${plannedShiftIndex}`"
                        pill
                        variant="primary"
                        class="m-25 text-nowrap"
                        @click="removeShift(plannedShift)"
                      >
                        {{ plannedShift.user.surname }} {{ plannedShift.user.name }}
                      </b-button>

                      <div v-if="roleShifts.plannedShifts.length < roleShifts.headcounts">
                        <h5 class="mt-1">
                          {{ $t('schedule.planner.available-employees') }}
                        </h5>
                        <div v-if="!day.loadingSchedules && roleShifts.options.length === 0">
                          <b-alert
                            variant="warning"
                            show
                            class="p-50 text-center"
                          >
                            {{ $t('schedule.no-available-employee') }}
                          </b-alert>
                        </div>

                        <div v-else>
                          <b-button
                            v-for="(option, optionIndex) in roleShifts.options"
                            :key="`option-${shiftDefinition.shiftDefinitionIdentifier}-${day.formatted}-${shiftDefinitionKey}-${role}-${optionIndex}`"
                            pill
                            variant="outline-secondary"
                            class="m-25 text-nowrap"
                            :disabled="!!option.shift"
                            @click="addShift(option, role)"
                          >
                            {{ option.user.surname }} {{ option.user.name }}
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </b-col>

                  <b-col
                    v-for="(teamShift, teamNameId) in day.schedules[shiftDefinition.shiftDefinitionIdentifier].teams"
                    :key="`team-shifts-${day.formatted}-${shiftDefinitionKey}-${teamNameId}`"
                    md="4"
                    class="mb-2"
                  >
                    <div class="border rounded p-1 h-100">
                      <h4>
                        {{ $t('team.title') }}: {{ teamShift.team.name }}
                        <b-avatar
                          v-if="allMembersSelected(teamShift.members)"
                          variant="success"
                          pill
                          size="sm"
                        >
                          <feather-icon icon="CheckIcon" />
                        </b-avatar>
                      </h4>
                      <div
                        v-for="(member, memberKey) in teamShift.members"
                        :key="`role-for-team-scheduling-${day.formatted}-${shiftDefinitionKey}-${teamNameId}-${memberKey}`"
                        class="mb-1"
                      >
                        <h5>{{ $t('roles.' + member.role) }}</h5>
                        <div v-if="member.selectedOption">
                          <b-button
                            pill
                            variant="primary"
                            class="m-25 text-nowrap"
                            @click="removeShift(member.selectedOption)"
                          >
                            {{ member.selectedOption.user.surname }} {{ member.selectedOption.user.name }}
                          </b-button>
                        </div>

                        <div v-if="!day.loadingSchedules && !member.selectedOption && member.options.length === 0">
                          <b-alert
                            variant="warning"
                            show
                            class="p-50 text-center"
                          >
                            {{ $t('schedule.no-available-employee') }}
                          </b-alert>
                        </div>

                        <div v-else-if="!member.selectedOption">
                          <b-button
                            v-for="(option, optionIndex) in member.options"
                            :key="`option-${shiftDefinition.shiftDefinitionIdentifier}-${day.formatted}-${shiftDefinitionKey}-${member.role}-${optionIndex}`"
                            size="sm"
                            pill
                            :variant="option.user.defaultTeamNameIds.includes(teamShift.team.id) ? 'outline-success' : 'outline-secondary'"
                            class="m-25 text-nowrap"
                            :disabled="!!option.shift"
                            @click="addShift(option, member.role, teamNameId)"
                          >
                            {{ option.user.surname }} {{ option.user.name }}
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </b-col>

                </b-row>
              </rc-overlay>
            </b-tab>
          </b-tabs>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>
<script>
import setup from '@/views/schedule/planner/ShiftsPlanner/setup'
import RcOverlay from '@/layouts/components/rc/RcOverlay.vue'

export default {
  components: { RcOverlay },
  setup,
}
</script>

<style scoped lang="scss">

</style>
